import React from "react"
import Reveal from "react-reveal/Reveal"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import devFeatures from "../../content/dev-features.yaml"
import Features from "../components/Features"
import IntegrationsPreview from "../components/IntegrationsPreview"
import PreregisterForm from "../components/Preregsiter"

// Developer feature and deeper explanation/value props page
const WhyPage = () => {
  return (
    <Layout navClass="w_menu" headerClass="transparent_header">
      <SEO title="Webhooks for developers" />

      <section className="breadcrumb_area">
        <img
          className="breadcrumb_shap"
          src={require("../images/breadcrumb/banner_bg.png")}
          alt=""
        />
        <div className="container">
          <div className="breadcrumb_content text-center">
            <Reveal effect="fadeInUp">
              <h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20">
                Webhooks for developers
              </h1>
            </Reveal>
            <p className="f_400 w_color f_size_16 l_height26">
              Focus on developing new features, not integrating webhooks
            </p>
          </div>
        </div>
      </section>

      <section className="prototype_service_info">
        <div className="symbols-pulse active">
          <div className="pulse-1"></div>
          <div className="pulse-2"></div>
          <div className="pulse-3"></div>
          <div className="pulse-4"></div>
          <div className="pulse-x"></div>
        </div>
        <div className="container">
          <h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb_90">
            HookActions is built for developers like you.
          </h2>
          <Features features={devFeatures.features} />
        </div>
      </section>
      <IntegrationsPreview />
      <div className="py-5 container">
        <h3 className="f_size_30 f_600 t_color3 l_height45 text-center">
          Can't convince your manager?
        </h3>
        <p className="f_400 f_size_16 l_height26 text-center">
          Send them a link to <Link to="/managers">this page</Link>.
        </p>
      </div>
      <PreregisterForm />
    </Layout>
  )
}

export default WhyPage
