import React, { Component } from "react"
import { Link } from "gatsby"

class BlogGridItem extends Component {
  render() {
    let {
      Title,
      description,
      url,
      image,
      colWidth = "4",
      contentClass = "",
    } = this.props
    return (
      <div className={`col-lg-${colWidth} mb-4`}>
        <div className="blog_list_item blog_list_item_two">
          <Link to={url}>
            <img
              className="img-fluid rounded"
              src={require("../images/" + image)}
              alt=""
            />
          </Link>
          <div className={`blog_content ${contentClass}`}>
            <Link to={url}>
              <h5 className="blog_title">{Title}</h5>
            </Link>
            <p>{description}</p>
            <div className="post-info-bottom">
              <Link to={url} className="learn_btn_two">
                Read more <i className="arrow_right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default BlogGridItem
