import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import BlogGridItem from "./BlogGridItem"

const IntegrationsPreview = () => {
  const data = useStaticQuery(graphql`
    query IntegrationsPreview {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/integrations/" } }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 4
      ) {
        edges {
          node {
            id
            excerpt
            frontmatter {
              title
              path
              image
              day: date(formatString: "DD")
              month: date(formatString: "MMM")
            }
          }
        }
      }
    }
  `)

  return (
    <section className="prototype_service_area_two">
      <div className="container custom_container text-center">
        <div className="sec_title mb_70">
          <h2 className="f_p f_size_30 t_color3 l_height50 f_600">
            Latest Integrations
          </h2>
          <p className="f_400 f_size_16 mb-0">
            Constantly updated, consistently improving.
          </p>
        </div>

        <div className="row">
          {data.allMarkdownRemark.edges.map(edge => (
            <BlogGridItem
              contentClass="p-4 text-left"
              key={edge.node.id}
              Title={edge.node.frontmatter.title}
              description={edge.node.excerpt}
              image={edge.node.frontmatter.image}
              url={edge.node.frontmatter.path}
            />
          ))}
        </div>

        <Link to="/integrations" className="seo_btn seo_btn_one btn_hover">
          View all &rsaquo;
        </Link>
      </div>
    </section>
  )
}

export default IntegrationsPreview
