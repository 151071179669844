import React from "react"
import Feature from "./Feature"

const Features = ({ features }) => (
  <div className="row p_service_info">
    {features.map((feature, i) => (
      <Feature
        key={i}
        title={feature.title}
        description={feature.description}
        icon={feature.icon}
        iconClass={feature.iconClass}
        containerClass={feature.containerClass}
      />
    ))}
  </div>
)

export default Features
