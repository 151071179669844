import React from "react"
import Reveal from "react-reveal/Reveal"

const Feature = ({
  title,
  description,
  icon,
  iconClass = "",
  containerClass = "",
}) => (
  <div className="col-lg-4 col-sm-6">
    <Reveal bottom cascade>
      <div className={`p_service_item ${containerClass}`}>
        <div className={`icon ${iconClass}`}>
          <i className={`ti-${icon}`} />
        </div>
        <h5 className="f_600 f_p t_color3">{title}</h5>
        <p className="f_400">{description}</p>
      </div>
    </Reveal>
  </div>
)

export default Feature
